<template>
  <div class="SitDown-box">
    <div class="Seat-setting-header">
      <div class="remainDayPosi">
        <div>
          <span class="title">{{ $t("front-kefu-moudle-v1-online-at") }}</span>
          <span class="remainDay" v-show="showfreeDays"
            >{{ $t("front-kefu-moudle-v1-mfsysy-time")
            }}<span class="remainDayNum">{{ Days }}</span></span
          >
        </div>
        <div class="content">
          {{ $t("front-kefu-moudle-v1-jchxzxzi-chat") }}
        </div>
      </div>
      <div class="Enable-button">
        <span v-if="EnableStatus" class="TurnedOn">{{
          $t("label.inusing")
        }}</span>
        <span v-if="!EnableStatus" class="NotOpen">{{
          $t("label.nousing")
        }}</span>
        <el-switch
          v-model="EnableStatus"
          active-color="#006DCC"
          inactive-color="#f0f0f0"
          @change="switchClick(false)"
        >
        </el-switch>
        <div>
          <el-dialog
            :title="$t('label.popup.infoz')"
            :visible.sync="closeChatVisible"
            top="35vh"
            customClass="customWidthClose"
          >
            <div>
              <span>{{ $t("front-kefu-moudle-v1-qdygbzx-chat") }}</span>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="cancelCloseChat">{{
                $t("label.cancel")
              }}</el-button>
              <el-button type="primary" @click="confirmCloseChat">{{
                $t("label.confirm")
              }}</el-button>
            </span>
          </el-dialog>
        </div>
      </div>
    </div>
    <div class="Seat-setting-footer">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <!-- 开通坐席 -->
        <el-tab-pane
          :label="$t('front-kefu-moudle-v1-Opened-chats')"
          :disabled="tabsBool"
          name="second"
        >
          <div>
            <div class="tablePosi">
              <div class="tableTitle">
                <div class="userinfo">
                  <span>{{ $t("front-kefu-moudle-v1-user-info") }}</span>
                </div>
              </div>
              <!-- 开通坐席表格 -->
              <div class="tablemain">
                <el-table
                  :data="tableDataUse"
                  border
                  style="width: 100%"
                  height="98"
                  v-loading="openLoading"
                >
                  <el-table-column min-width="180">
                    <template slot="header">
                      <span>{{ $t("label.operate") }}</span>
                    </template>
                    <template slot-scope="scope">
                      <div class="atags">
                        <a
                          href="javascript:void(0)"
                          @click="handleEditUse(scope.row, scope.$index)"
                          v-if="!showfreeDays"
                        >
                          <span>{{ $t("label.modify") }}</span></a
                        >
                        <a
                          href="javascript:void(0)"
                          class="disablemarkA"
                          v-else
                        >
                          <span>{{ $t("label.modify") }}</span></a
                        >
                        |
                        <a href="javascript:void(0)" @click="switchClick(true)">
                          <span>{{ $t("label.tabpage.closez") }}</span></a
                        >
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :prop="item.name"
                    v-for="(item, index) in headListUse"
                    :key="index"
                    :label="item.fieldLabel"
                    min-width="250"
                  >
                    <template slot-scope="scope">
                      <div v-if="item.id == '001'">
                        {{ scope.row[item.name] }}
                      </div>
                      <div v-else>{{ scope.row[item.name] | formatPass }}</div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
          <div class="setting-pro">
            <div class="Widget-setting-header">
              <div>
                <h3>{{ $t("lable.chat.advanced.setting") }}</h3>
                <h5>
                  {{ $t("lable.real.time.chat") }}
                </h5>
              </div>
            </div>
            <div class="Widget-setting-footer">
              <el-button @click="getJWT">{{
                $t("lable.chat.advanced.setting")
              }}</el-button>
            </div>
            <el-dialog
              title=""
              custom-class="huanxin"
              :visible.sync="dialogHuanxin"
              width="70%"
            >
              <div>
                <iframe
                  :src="URL"
                  frameborder="0"
                  style="width: 100%; height: 600px"
                ></iframe>
              </div>
            </el-dialog>
          </div>
        </el-tab-pane>
        <!-- 绑定坐席 -->
        <el-tab-pane
          :label="$t('front-kefu-moudle-v1-bind-seats')"
          name="three"
          :disabled="tabsBool"
        >
          <div class="tablePosi">
            <div class="tableTitle">
              <div class="userinfo" v-show="!showBindcno">
                <span>{{ $t("front-kefu-moudle-v1-user-info") }}</span>
              </div>
              <div class="savetable">
                <el-button plain @click="newBindcno">{{
                  $t("label.ems.create")
                }}</el-button>
              </div>
              <div class="savetableInput" v-show="showBindcno">
                <el-input v-model="placeInput"></el-input>
              </div>
            </div>

            <!-- 绑定坐席表格 -->
            <div class="tablemain" v-show="!showBindcno">
              <el-table
                :data="tableDataBind"
                border
                style="width: 100%"
                height="526"
                v-loading="bindLoading"
              >
                <el-table-column min-width="180">
                  <template slot="header">
                    <span>{{ $t("label.operate") }}</span>
                  </template>
                  <template slot-scope="scope">
                    <div class="atags">
                      <a
                        href="javascript:void(0)"
                        @click="handleEditBind(scope.row, scope.$index)"
                      >
                        <span>{{ $t("label.modify") }}</span></a
                      >
                      |
                      <a
                        href="javascript:void(0)"
                        @click="handleDeleteBind(scope.row, scope.$index)"
                        ><span>{{ $t("label.delete") }}</span></a
                      >
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :prop="item.name"
                  v-for="(item, index) in headListBind"
                  :key="index"
                  :label="item.fieldLabel"
                  min-width="180"
                  :show-overflow-tooltip="
                    item.id === '005' || item.id === '002' ? false : true
                  "
                >
                  <template slot-scope="scope">
                    <div v-if="item.id == '005'">
                      <div v-if="!scope.row.status">
                        <el-checkbox
                          v-model="DisableStatus"
                          disabled
                        ></el-checkbox>
                      </div>
                      <div v-else>
                        <el-checkbox
                          v-model="isEnableStatus"
                          v-if="scope.row.status == 'Enable'"
                          disabled
                        ></el-checkbox>
                        <el-checkbox
                          v-model="DisableStatus"
                          v-else-if="scope.row.status == 'Submit'"
                          disabled
                        ></el-checkbox>
                      </div>
                    </div>
                    <div v-else-if="item.id == '002'">
                      <div v-if="!scope.row.nicename" class="tiperrorStyle">
                        <span class="tiperrorContent">{{
                          $t("front-kefu-moudle-v1-Connection-failure")
                        }}</span>
                        <el-popover
                          placement="top"
                          width="144"
                          trigger="hover"
                          :content="$t('front-kefu-moudle-v1-hxzxybsc-again')"
                          popper-class="popCustomZ"
                        >
                          <svg
                            class="iconStyle"
                            aria-hidden="true"
                            slot="reference"
                          >
                            <use href="#icon-prompt1"></use>
                          </svg>
                        </el-popover>
                      </div>
                      <div v-else>
                        <el-popover
                          placement="top"
                          trigger="hover"
                          :content="scope.row.nicename"
                          popper-class="popCustomZT"
                        >
                          <span slot="reference">{{ scope.row.nicename }}</span>
                        </el-popover>
                      </div>
                    </div>
                    <div v-else>{{ scope.row[item.name] }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 新建坐席 -->
            <div class="newSeatsStyle">
              <el-dialog
                :title="newCnoTitle"
                :visible.sync="newBindCnoVisible"
                top="20vh"
                customClass="customWidthnew"
                @closed="closeUpnewBindCno('ruleFormNewCno')"
              >
                <div class="newDialog">
                  <el-form
                    :model="ruleFormNewCno"
                    :rules="rulesNewCno"
                    ref="ruleFormNewCno"
                    label-width="80px"
                    class="demo-ruleForm"
                    label-position="left"
                    :hide-required-asterisk="true"
                  >
                    <el-form-item
                      :label="$t('label.tabpage.userz')"
                      prop="user"
                    >
                      <el-input
                        v-model="ruleFormNewCno.user"
                        :placeholder="$t('label.tabpage.entercontent')"
                        @focus="remoteSearchOwner"
                      >
                        <i
                          slot="suffix"
                          class="el-input__icon el-icon-search"
                        ></i
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="$t('front-kefu-moudle-v1-Select-seats')"
                      prop="HasCno"
                    >
                      <el-select
                        v-model="ruleFormNewCno.HasCno"
                        :placeholder="
                          $t('front-kefu-moudle-v1-xzhxzxkf-online')
                        "
                      >
                        <el-option
                          v-for="(item, index) in selectCnooption"
                          :key="index"
                          :label="item.nicename"
                          :value="item.userId"
                          @click.native="selectCnoChange(item)"
                          :disabled="item.status == 'Submit'"
                        >
                          <span style="float: left">{{ item.nicename }}</span>
                          <span v-if="item.status == 'Submit'">
                            <span
                              style="
                                float: left;
                                color: #c0c4cc;
                                font-size: 13px;
                              "
                              >({{ $t("label.nousing") }})</span
                            ></span
                          >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      :label="$t('front-kefu-moudle-v1-Seats-name')"
                      prop="cnoName"
                    >
                      <el-input
                        v-model="ruleFormNewCno.cnoName"
                        :placeholder="
                          $t('front-kefu-moudle-v1-maxchars-input', [45])
                        "
                        :maxlength="45"
                        show-word-limit
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="$t('label.department.phone')"
                      prop="phone"
                    >
                      <el-input
                        v-model="ruleFormNewCno.phone"
                        :placeholder="$t('label.weixin.input.telephone')"
                        @blur="testPhone('ruleFormNewCno')"
                      ></el-input>
                    </el-form-item>
                    <div class="tipStyle" v-show="errorPhone">
                      {{ $t("front-kefu-moudle-v1-sjgsbzq-reinput") }}
                    </div>
                    <el-form-item :label="$t('label.email2')" prop="email">
                      <el-input
                        v-model="ruleFormNewCno.email"
                        :placeholder="$t('front-kefu-moudle-v1-input-email')"
                        @blur="testEmail('ruleFormNewCno')"
                        :disabled="emailDisable"
                      ></el-input>
                    </el-form-item>
                    <div class="tipStyle" v-show="errorEmail">
                      {{ $t("front-kefu-moudle-v1-yxgsbzq-reinput") }}
                    </div>
                    <el-form-item
                      :label="$t('label.password')"
                      prop="password"
                      v-if="showInputPass"
                    >
                      <div class="passPadding">
                        <el-input
                          v-model="ruleFormNewCno.password"
                          show-password
                          :placeholder="
                            $t('front-kefu-moudle-v1-maxchars-input', [12])
                          "
                          @blur="testPassword('ruleFormNewCno')"
                        >
                          <svg
                            class="Iconstyle"
                            aria-hidden="true"
                            slot="suffix"
                            v-show="!errorPassword && showCorrect"
                          >
                            <use href="#icon-a-passwordCorrect"></use>
                          </svg>
                        </el-input>
                      </div>
                    </el-form-item>
                    <div class="tipStyle" v-show="errorPassword">
                      {{ $t("front-kefu-moudle-v1-jymm-reinput") }}
                    </div>
                  </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="closeUpnewBindCno('ruleFormNewCno')">{{
                    $t("label.cancel")
                  }}</el-button>
                  <el-button
                    type="primary"
                    @click="confirmnewBindCno('ruleFormNewCno')"
                    :disabled="newCnoBtn"
                    >{{ $t("label.confirm") }}</el-button
                  >
                </span>
              </el-dialog>
            </div>
            <!-- 删除绑定列表 -->
            <div class="deleteSeatsStyle">
              <el-dialog
                :title="$t('label.delete')"
                :visible.sync="deleteSeatsVisible"
                top="36vh"
                customClass="customWidthdelete"
                @closed="closedeleteSeat"
              >
                <span>{{ $t("front-kefu-moudle-v1-chzx-confirm") }}</span>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="closedeleteSeat">{{
                    $t("label.cancel")
                  }}</el-button>
                  <el-button type="primary" @click="confirmdeleteSeat">{{
                    $t("label.confirm")
                  }}</el-button>
                </span>
              </el-dialog>
            </div>
          </div>

          <div v-show="showBindcno">
            {{ $t("front-kefu-moudle-v1-rgdyhcwzx-reply") }}
          </div>
        </el-tab-pane>
        <!-- 空白页 -->
        <el-tab-pane label="" name="first" disabled="true">
          <div class="parentBtnPosi">
            <div class="openSeatsBtnPosi">
              <img :src="studyImg" alt="" />
              <div class="openSeatsBtn">
                <div v-show="twoOroneBtn">
                  <div class="customBtn">
                    <el-button type="primary" @click="openBtnClick">{{
                      $t("front-kefu-moudle-v1-Opened-chats")
                    }}</el-button>
                  </div>
                </div>

                <div v-show="twoOroneBtn">
                  <el-button type="primary" @click="freeBtnClick">{{
                    $t("front-kefu-moudle-v1-use-free")
                  }}</el-button>
                </div>
                <div v-show="!twoOroneBtn">
                  <el-button type="primary" @click="openBtnClick">{{
                    $t("front-kefu-moudle-v1-Opened-chats")
                  }}</el-button>
                </div>
              </div>
              <div v-show="!twoOroneBtn">
                {{ $t("front-kefu-moudle-v1-sydq-buyuser") }}
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- 开启聊天功能框 -->
      <openChatlogin
        :openChatDialog="openChatDialog"
        :openTitle="openTitle"
        :openChatruleFormPass="openChatruleFormPass"
        @confirmOpenChatPass="confirmOpenChatPass"
        @closeOpenChatPass="closeOpenChatPass"
      ></openChatlogin>
      <!-- 查找/查找多选 -->
      <!-- 搜索 -->
      <el-dialog
        :title="$t('label.searchs')"
        custom-class="dialog-form-wrapper"
        width="60%"
        top="5%"
        style="height: 91%"
        :visible.sync="showSearchTable"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :modal="true"
        append-to-body
        @close="clear"
      >
        <search-table
          ref="searchTable"
          @changeSelect="changeSelectOwner"
          :userstr="userstr"
          :bindtype="bindtype"
        />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import openChatlogin from "@/views/systemSettings/components/chatSetting/components/openChatDialog.vue";
import SearchTable from "@/views/systemSettings/components/components/searchTable.vue";
import * as chatsettingsApi from "./api.js";
import { getJWT, judgeAdmin } from "./api.js";

export default {
  computed: {
    inputData: function () {
      const { user, HasCno, cnoName } = this.ruleFormNewCno;
      return { user, HasCno, cnoName };
    },
  },
  watch: {
    inputData: function (newVal) {
      if ((newVal.user && newVal.HasCno) === "") {
        this.newCnoBtn = true;
      } else if ((newVal.user && newVal.HasCno) !== "") {
        if (!newVal.HasCno && newVal.cnoName === "") {
          this.newCnoBtn = true;
        } else {
          this.newCnoBtn = false;
        }
      }
    },
  },
  data() {
    var validatePhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/;
      if (value === "") {
        callback(new Error(" "));
      } else if (!Number.isInteger(+value)) {
        callback(new Error(" "));
      } else {
        if (phoneReg.test(value)) {
          callback();
        } else {
          callback(new Error(" "));
        }
      }
    };
    var validatePassword = (rule, value, callback) => {
      const passwordReg =
        /^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)(?![\W_]+$)\S.{1,11}$/;
      if (passwordReg.test(value)) {
        callback();
      } else {
        callback(new Error(" "));
      }
    };

    return {
      studyImg: require("@/views/systemSettings/components/chatSetting/Img/study.png"),
      // 开通坐席loading
      openLoading: true,
      // 绑定坐席loading
      bindLoading: true,
      // 开关
      EnableStatus: false,
      // 默认显示剩余天数
      showfreeDays: false,
      // 默认剩余天数
      Days: 0,
      // 默认tab
      activeName: "first",
      // 免费试用 开通坐席
      twoOroneBtn: true,
      // 关闭聊天弹窗
      closeChatVisible: false,
      // 默认tab禁用
      tabsBool: true,
      // 开启聊天功能会话框
      openChatDialog: false,
      // 开启聊天功能会话框题目
      openTitle: "",
      // 开通坐席列表表头
      headListUse: [
        {
          id: "001",
          fieldLabel: this.$i18n.t("front-kefu-moudle-v1-tenant-ID"),
          name: "tenantId",
        },
        {
          id: "002",
          fieldLabel: "clientID",
          name: "clientId",
        },
        {
          id: "003",
          fieldLabel: "secretID",
          name: "secretId",
        },
        {
          id: "004",
          fieldLabel: this.$i18n.t("front-kefu-moudle-v1-publicKey"),
          name: "ssosecretId",
        },
        {
          id: "005",
          fieldLabel: this.$i18n.t("front-kefu-moudle-v1-single-Login-area"),
          name: "ssodomain",
        },
      ],
      // 开通坐席表格
      tableDataUse: [],
      // 绑定坐席列表表头
      headListBind: [
        {
          id: "001",
          fieldLabel: this.$i18n.t("label.user"),
          name: "userName",
        },
        {
          id: "002",
          fieldLabel: this.$i18n.t("front-kefu-moudle-v1-Seats-name"),
          name: "nicename",
        },
        {
          id: "003",
          fieldLabel: this.$i18n.t("label.department.phone"),
          name: "mobilePhone",
        },
        {
          id: "004",
          fieldLabel: this.$i18n.t("label.email2"),
          name: "email",
        },
        {
          id: "005",
          fieldLabel: this.$i18n.t("label.group.isusing"),
          name: "status",
        },
      ],
      // 默认绑定坐席显示
      showBindcno: false,
      // 默认新建编辑框头部
      newCnoTitle: "",
      // 绑定坐席表格
      tableDataBind: [],
      isEnableStatus: true,
      DisableStatus: false,
      // 新建坐席框
      newBindCnoVisible: false,
      // 新建坐席保存按钮
      newCnoBtn: true,
      // 新建坐席表单
      ruleFormNewCno: {
        huanxinUserId: "",
        dataId: "",
        userid: "",
        user: "",
        HasCno: "",
        cnoName: "",
        phone: "",
        email: "",
        password: "",
      },
      SelectownObj: {
        userid: "",
        phone: "",
        email: "",
      },
      rulesNewCno: {
        phone: [{ validator: validatePhone, trigger: "blur" }],
        email: [
          {
            required: true,
            type: "email",
            trigger: "blur",
            message: " ",
          },
        ],
        password: [{ validator: validatePassword, trigger: "blur" }],
      },
      // 选择已有坐席
      selectCnooption: [],
      // 默认密码框
      showInputPass: false,
      // 已选用户
      userstr: [],
      // 搜索用户会话框
      showSearchTable: false,
      // 手机号验证失败
      errorPhone: false,
      // 邮箱验证失败
      errorEmail: false,
      // 密码验证失败
      errorPassword: false,
      // 删除框
      deleteSeatsVisible: false,
      // 删除ID
      deleteId: "",
      // 密码框icon
      showCorrect: false,
      // 查找用户的参数
      bindtype: "",
      // 编辑邮箱禁用
      emailDisable: false,
      // 编辑还是新建
      editOrNew: true,
      // 环信高级设置
      dialogHuanxin: false,
    };
  },
  components: {
    openChatlogin,
    SearchTable,
  },
  filters: {
    formatPass(val) {
      if (val) {
        let passVal = "";
        let passVallength = val.length;
        while (passVallength > 0) {
          passVal += "*";
          passVallength--;
        }
        return passVal;
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    // 初始化
    init() {
      this.activeName = "first";
      this.placeInput = this.$i18n.t("front-kefu-moudle-v1-noadd-online-chats");
      // 判断该组织是否可以试用
      chatsettingsApi.getTrialQualification().then((res) => {
        // 可以试用
        if (res.data.code == 200) {
          this.EnableStatus = false;
          this.twoOroneBtn = true;
          this.tabsBool = true;
          this.showfreeDays = false;
        }
        // 不可试用
        else if (res.data.code == 400) {
          this.twoOroneBtn = false;
        }
        if (res.data.config.status == 0 || res.data.config.status == 1) {
          this.EnableStatus = true;
          this.tabsBool = false;
          this.activeName = "three";
          this.getBindTable();
          this.getOpenTable();
          if (res.data.config.numberType == 0) {
            this.showfreeDays = true;
            this.Days = res.data.config.trialCountdown;
          } else if (res.data.config.numberType == 1) {
            this.showfreeDays = false;
          }
        } else if (res.data.config.status == 3 || res.data.config.status == 2) {
          this.EnableStatus = false;
          this.tabsBool = true;
          this.activeName = "first";
          this.showfreeDays = false;
        }
      });
    },

    // 获取开通列表
    getOpenTable() {
      this.tableDataUse = [];
      chatsettingsApi.getTrialQualification().then((res) => {
        let OpenTableObj = {
          tenantId: res.data.config.tenantId,
          clientId: res.data.config.clientId,
          secretId: res.data.config.secretId,
          ssosecretId: res.data.config.ssosecretId,
          ssodomain: res.data.config.ssodomain,
        };
        this.tableDataUse.push(OpenTableObj);
        this.openLoading = false;
      });
    },

    // 获取绑定列表
    getBindTable() {
      chatsettingsApi.getBindAgents().then((res) => {
        if (res.result) {
          this.tableDataBind = res.data;
          if (this.tableDataBind.length > 0) {
            this.showBindcno = false;
          } else {
            this.showBindcno = true;
          }
          this.bindLoading = false;
        }
      });
    },

    // 免费试用
    freeBtnClick() {
      chatsettingsApi.TrialAccount().then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: this.$i18n.t("label.group.isusing.success"),
            type: "success",
          });
          this.EnableStatus = true;
          this.activeName = "three";
          this.tabsBool = false;
          this.init();
        } else if (res.data.code == 400 || res.data.code == 401) {
          this.tabsBool = true;
          this.$message(this.$i18n.t("front-kefu-moudle-v1-lxndxsdb-free"));
        }
      });
    },
    // 开通坐席
    openBtnClick() {
      this.openTitle = this.$i18n.t("front-kefu-moudle-v1-Opened-chats");
      chatsettingsApi.getTrialQualification().then((res) => {
        if (res.data.config) {
          if (res.data.config.numberType == 1) {
            this.openChatruleFormPass = {
              activityId: res.data.config.tenantId,
              clientID: res.data.config.clientId,
              secretID: res.data.config.secretId,
              ssosecretId: res.data.config.ssosecretId,
              ssodomain: res.data.config.ssodomain,
            };
            this.openChatDialog = true;
          } else if (res.data.config.numberType == 0) {
            this.openChatruleFormPass = {
              activityId: "",
              clientID: "",
              secretID: "",
              ssosecretId: "",
              ssodomain: "",
            };
            this.openChatDialog = true;
          // eslint-disable-next-line no-dupe-else-if
          } else if (res.data.config.numberType == 0) {
            this.openChatruleFormPass = {
              activityId: "",
              clientID: "",
              secretID: "",
            };
            this.openChatDialog = true;
          }
        } else {
          this.openChatruleFormPass = {
            activityId: "",
            clientID: "",
            secretID: "",
            ssosecretId: "",
            ssodomain: "",
          };
          this.openChatDialog = true;
        }
      });
    },

    // 点击关闭坐席
    switchClick(btnMark) {
      if (btnMark) {
        this.EnableStatus = true;
        this.closeChatVisible = true;
      } else {
        if (this.EnableStatus) {
          this.EnableStatus = false;
        } else {
          this.EnableStatus = true;
          this.closeChatVisible = true;
        }
      }
    },

    // 确认关闭弹窗
    confirmCloseChat() {
      chatsettingsApi.getTrialQualification().then((res) => {
        let params = {
          orgId: res.data.config.orgId,
          status: 2,
        };
        chatsettingsApi.changeHuanxinState(params).then((res) => {
          if (res.result) {
            this.closeChatVisible = false;
            this.EnableStatus = false;
            this.init();
            this.$message({
              message: this.$i18n.t("mobel_web_closed"),
              type: "success",
            });
          }
        });
      });
    },

    // 取消关闭弹窗
    cancelCloseChat() {
      this.closeChatVisible = false;
    },

    // 确认开启坐席
    confirmOpenChatPass() {
      this.openChatDialog = false;
      this.EnableStatus = true;
      this.tabsBool = false;
      this.activeName = "three";
      this.bindLoading = true;
      this.getBindTable();
      this.openLoading = true;
      this.getOpenTable();
    },

    // 关闭开启坐席弹窗
    closeOpenChatPass() {
      this.openChatDialog = false;
    },

    // 编辑用户信息
    handleEditUse(item) {
      this.openTitle = this.$i18n.t("label.modify");
      this.openChatruleFormPass = {
        activityId: item.tenantId,
        clientID: item.clientId,
        secretID: item.secretId,
        ssosecretId: item.ssosecretId,
        ssodomain: item.ssodomain,
      };
      this.openChatDialog = true;
    },

    // 新建坐席
    newBindcno() {
      this.newBindCnoVisible = true;
      this.newCnoTitle = this.$i18n.t("front-kefu-moudle-v1-new-chats");
      this.ruleFormNewCno.dataId = "";
      this.ruleFormNewCno.user = "";
      this.ruleFormNewCno.cnoName = "";
      this.ruleFormNewCno.phone = "";
      this.ruleFormNewCno.email = "";
      this.ruleFormNewCno.roleid = "";
      this.ruleFormNewCno.HasCno = "";
      chatsettingsApi.getAgents().then((res) => {
        this.selectCnooption = res.data.entities;
        this.selectCnooption.unshift({
          nicename: this.$i18n.t("front-kefu-moudle-v1-new-online-chats"),
        });
        if (res.data.entities.length == 0) {
          this.ruleFormNewCno.HasCno = this.selectCnooption[0].nicename;
        } else {
          this.ruleFormNewCno.HasCno = "";
        }
      });
      this.editOrNew = false;
    },

    // 查找用户
    remoteSearchOwner() {
      this.userstr = [];
      this.showSearchTable = true;
      this.bindtype = "huanxin";
      this.$nextTick(() => {
        this.$refs.searchTable.init();
      });
    },

    changeSelectOwner(row) {
      this.SelectownObj.userid = row.id;
      this.ruleFormNewCno.userid = row.id;
      this.SelectownObj.phone = row.mobile || "";
      this.SelectownObj.email = row.email || "";
      this.ruleFormNewCno.user = row.name || "";
      this.showSearchTable = false;
      this.ruleFormNewCno.password = "";
      this.$refs["ruleFormNewCno"].clearValidate();
      this.errorPhone = false;
      this.errorEmail = false;
      this.errorPassword = false;
      this.showCorrect = false;
    },

    // 选择已有坐席
    selectCnoChange(item) {
      // 选择新建坐席
      if (!item.status) {
        this.showInputPass = true;
        this.ruleFormNewCno.cnoName = "";
        this.ruleFormNewCno.password = "";
        this.ruleFormNewCno.huanxinUserId = "";
        if (this.ruleFormNewCno.user == "") {
          this.SelectownObj.phone = "";
          this.SelectownObj.email = "";
        }
        this.ruleFormNewCno.phone = this.SelectownObj.phone;
        this.ruleFormNewCno.email = this.SelectownObj.email;
        this.emailDisable = false;
      }
      // 选择环信已有坐席
      else {
        if (item.status == "Enable") {
          this.showInputPass = false;
          this.ruleFormNewCno.huanxinUserId = item.userId;
          this.ruleFormNewCno.cnoName = item.nicename;
          this.ruleFormNewCno.phone = item.mobilePhone;
          this.ruleFormNewCno.email = item.username;
          this.ruleFormNewCno.password = item.password;
          this.ruleFormNewCno.roleid = item.roleList[0].role_id;
          this.emailDisable = true;
        }
      }
      this.$refs["ruleFormNewCno"].clearValidate();
      this.errorPhone = false;
      this.errorEmail = false;
      this.errorPassword = false;
      this.showCorrect = false;
    },

    // 确认新建坐席
    confirmnewBindCno(formName) {
      this.$refs[formName].validate((valid, errObj) => {
        if (valid) {
          let params = {};
          // 选择已有坐席
          if (!this.showInputPass) {
            params = {
              huanxinUserId: this.ruleFormNewCno.huanxinUserId,
              userId: this.ruleFormNewCno.userid,
              email: this.ruleFormNewCno.email,
              userName: this.ruleFormNewCno.user,
              nicename: this.ruleFormNewCno.cnoName,
              mobilePhone: this.ruleFormNewCno.phone,
              id: this.ruleFormNewCno.dataId,
              roleId: this.ruleFormNewCno.roleid,
            };
          }
          // 选择新建
          else {
            params = {
              userId: this.ruleFormNewCno.userid,
              email: this.ruleFormNewCno.email,
              userName: this.ruleFormNewCno.user,
              nicename: this.ruleFormNewCno.cnoName,
              mobilePhone: this.ruleFormNewCno.phone,
              password: this.ruleFormNewCno.password,
              id: this.ruleFormNewCno.dataId,
            };
          }

          chatsettingsApi.bindAgent(params).then((res) => {
            if (res.result) {
              if (!this.editOrNew) {
                this.$message({
                  message: this.$i18n.t("vue_label_notice_new_success"),
                  type: "success",
                });
              } else {
                this.$message({
                  message: this.$i18n.t("vue_label_notice_modify_success"),
                  type: "success",
                });
              }
              this.$refs[formName].resetFields();
              this.emailDisable = false;
              this.errorPhone = false;
              this.errorEmail = false;
              this.errorPassword = false;
              this.newBindCnoVisible = false;
              this.bindLoading = true;
              this.getBindTable();
              this.openLoading = true;
              this.getOpenTable();
            }
          });
        } else {
          if (errObj.phone) {
            this.errorPhone = true;
          } else if (!errObj.phone) {
            this.errorPhone = false;
          }
          if (errObj.email) {
            this.errorEmail = true;
          } else if (!errObj.email) {
            this.errorEmail = false;
          }
          if (this.showInputPass) {
            if (errObj.password) {
              this.errorPassword = true;
            } else if (!errObj.password) {
              this.errorPassword = false;
            }
          }
          return false;
        }
      });
    },

    // 取消新建坐席
    closeUpnewBindCno(formName) {
      this.$refs[formName].resetFields();
      this.emailDisable = false;
      this.errorPhone = false;
      this.errorEmail = false;
      this.errorPassword = false;
      this.showInputPass = false;
      this.newBindCnoVisible = false;
      this.showCorrect = false;
    },

    // 校验手机号
    testPhone(formName) {
      this.$refs[formName].validateField("phone", (emailError) => {
        if (!emailError) {
          this.errorPhone = false;
        } else {
          this.errorPhone = true;
          return false;
        }
      });
    },

    // 校验邮箱
    testEmail(formName) {
      this.$refs[formName].validateField("email", (emailError) => {
        if (!emailError) {
          this.errorEmail = false;
        } else {
          this.errorEmail = true;
          return false;
        }
      });
    },

    // 校验密码
    testPassword(formName) {
      this.$refs[formName].validateField("password", (emailError) => {
        if (!emailError) {
          this.errorPassword = false;
          this.showCorrect = true;
        } else {
          this.errorPassword = true;
          this.showCorrect = false;
          return false;
        }
      });
    },

    // 编辑绑定列表
    handleEditBind(item) {
      this.newBindCnoVisible = true;
      this.newCnoTitle = this.$i18n.t("front-kefu-moudle-v1-edit-chats");
      this.ruleFormNewCno.huanxinUserId = item.huanxinUserId;
      this.ruleFormNewCno.dataId = item.id;
      this.ruleFormNewCno.userid = item.userId;
      this.ruleFormNewCno.user = item.userName;
      this.ruleFormNewCno.HasCno = item.nicename;
      this.ruleFormNewCno.cnoName = item.nicename;
      this.ruleFormNewCno.phone = item.mobilePhone;
      this.ruleFormNewCno.email = item.email;
      this.ruleFormNewCno.roleid = item.roleId;
      this.showInputPass = false;
      chatsettingsApi.getAgents().then((res) => {
        this.selectCnooption = res.data.entities;
      });
      this.emailDisable = true;
      this.editOrNew = true;
    },

    // 删除绑定列表
    handleDeleteBind(item) {
      this.deleteSeatsVisible = true;
      this.deleteId = item.id;
    },

    // 确认删除
    confirmdeleteSeat() {
      let params = {
        id: this.deleteId,
      };
      chatsettingsApi.delBindAgent(params).then((res) => {
        if (res.result) {
          this.$message({
            message: this.$i18n.t("label.tabpage.delsuccessz"),
            type: "success",
          });
          this.deleteSeatsVisible = false;
          this.deleteId = "";
          this.bindLoading = true;
          this.getBindTable();
          this.openLoading = true;
          this.getOpenTable();
        }
      });
    },

    closedeleteSeat() {
      this.deleteSeatsVisible = false;
    },
    async getJWT() {
      let adminResult = await judgeAdmin();
      if (adminResult.result) {
        if (adminResult.data.isadmin) {
          let result = await getJWT();
          if (result.result) {
            if (result.returnCode != "1") {
              this.$message({
                message: result.returnInfo,
                type: "warning",
              });
            } else if (result.returnCode === "1") {
              this.jwt = result.data.jwt;
              let domain = result.data.domain;
              //iframe嵌入环信工作台
              this.URL = `https://${domain}/v1/access/jwt?jwt=${this.jwt}&returnTo=https://${domain}/mo/admin/statistic/index`;
              this.dialogHuanxin = true;
            }
          }
        } else {
          // 请先绑定为环信管理员坐席再进入高级设置
          this.$message.warning(this.$i18n.t('c59'));
        }
      }
    },
  },
};
</script>
<style lang="scss">
.el-popover.popCustomZ.el-popover--plain {
  padding: 16px 10px;
  line-height: 20px;
  background-color: #283c79;
  color: #ffffff;
  .popper__arrow::after {
    border-top-color: #283c79;
  }
}
.el-popover.popCustomZT {
  min-width: 10px !important;
  padding: 10px;
  background-color: #283c79;
  color: #ffffff;
  .popper__arrow::after {
    border-top-color: #283c79;
  }
}
</style>
<style lang="scss" scoped>
h3,
h5 {
  margin: 0;
}
.SitDown-box {
  padding: 20px 0;
}
.Seat-setting-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dedcda;
  padding: 0 20px 20px 20px;
  .remainDayPosi {
    width: 400px;
    .title {
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
    }
    .remainDay {
      display: inline-block;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      padding: 0 8px;
      height: 22px;
      line-height: 22px;
      background: #006dcc;
      border-radius: 3px;
      margin-left: 16px;
      .remainDayNum {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 3px;
        text-align: center;
        line-height: 16px;
        color: #006dcc;
        background-color: #ffffff;
        margin-left: 8px;
      }
    }
    .content {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #333333;
      margin-top: 8px;
    }
  }

  .Enable-button {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      margin-right: 10px;
    }
    .TurnedOn {
      color: #006dcc;
    }
    .NotOpen {
      color: #999999;
    }
    ::v-deep .customWidthClose {
      width: 400px;
    }
  }
}
.Seat-setting-footer {
  ::v-deep .el-tabs__nav-scroll {
    padding: 0 20px;
  }
  ::v-deep .el-tab-pane {
    padding: 0 20px;
  }
  ::v-deep .el-tabs__item.is-disabled {
    color: #080707;
  }
}
.parentBtnPosi {
  min-height: calc(100vh - 366px);
  display: grid;
  place-items: center;
}
.openSeatsBtnPosi {
  width: 430px;
  height: 430px;
  margin: 0 auto;
  text-align: center;
  .openSeatsBtn {
    display: flex;
    justify-content: space-evenly;
    width: 250px;
    margin: 30px auto;
    .customBtn {
      ::v-deep .el-button {
        background-color: #fff;
        color: #409eff;
      }
      ::v-deep .el-button:hover {
        color: #409eff;
        border-color: #c6e2ff;
        background-color: #ecf5ff;
      }
    }
  }
}
.tablePosi {
  margin-bottom: 10px;
  border: 1px #dcdcdc solid;
  border-radius: 3px;
  background-color: #f8f8f8;
  padding: 10px 10px 10px 10px;
  .tableTitle {
    line-height: 38px;
    .userinfo {
      float: left;
      margin-bottom: 16px;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
    }
    .savetable {
      display: flex;
      justify-content: center;
    }
    .savetableInput {
      margin-top: 10px;
    }
  }
  .tablemain {
    ::v-deep .el-table thead {
      color: #333333;
    }
    ::v-deep .el-table--border th,
    .el-table__fixed-right-patch {
      background-color: #f0f0f0;
      border: 1px solid #dcdcdc;
    }
    ::v-deep .el-table--border td {
      border-right: none;
    }
    .atags {
      .disablemarkA {
        color: #c8c6c6;
      }
      a {
        color: #006dcc;
      }
    }
    ::v-deep .el-form-item {
      margin-bottom: 10px;
    }
    ::v-deep .el-table .cell div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .tiperrorStyle {
      .tiperrorContent {
        color: #ed4b41;
      }
      .iconStyle {
        width: 16px;
        height: 16px;
        float: right;
        margin-top: 3px;
      }
    }
  }
  ::v-deep .customWidth {
    width: 472px;
  }
  .newSeatsStyle {
    ::v-deep .customWidthnew {
      width: 472px;
    }
    ::v-deep .el-dialog__body {
      padding-top: 0;
    }
  }
}
.newDialog {
  ::v-deep .el-select {
    width: 100%;
  }
  ::v-deep .el-form-item {
    margin-bottom: 0px;
    margin-top: 19px;
  }
  .tipStyle {
    margin-left: 80px;
    margin-top: 2px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #ed4b41;
  }
  .Iconstyle {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 14px;
    right: 25px;
  }
}
.deleteSeatsStyle {
  ::v-deep .customWidthdelete {
    width: 420px;
  }
}
.passPadding {
  ::v-deep .el-input--suffix .el-input__inner {
    padding-right: 50px;
  }
}

.Widget-setting-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  h3 {
    margin-bottom: 10px;
  }
  .Enable-button {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      margin-right: 10px;
    }
    .TurnedOn {
      color: #006dcc;
    }
    .NotOpen {
      color: #f0f0f0;
    }
  }
}
.Widget-setting-footer {
  padding-bottom: 20px;
  .script-box {
    width: 100%;
    min-height: 110px;
    background: #f8f8f8;
    border-radius: 3px;
    border: 1px solid #dedcda;
    padding: 20px;
    cursor: pointer;
  }
  .el-button {
    background: #006dcc;
    color: #ffffff;
  }
}
.setting-pro {
  ::v-deep .el-dialog__header {
    padding-top: 45px;
  }
}
</style>