<template>
  <div class="openChatDialog">
    <el-dialog
      :title="openTitle"
      :visible.sync="openChatDialogProp"
      customClass="customWidth"
      top="16%"
      @closed="closeOpenChat('ruleForm')"
    >
      <el-form
        :model="openChatruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="110px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item prop="activityId">
          <label slot="label"
            ><span style="color: red">*</span>&nbsp;{{
              $t("front-kefu-moudle-v1-tenant-ID")
            }}</label
          >
          <el-input
            v-model="openChatruleForm.activityId"
            :placeholder="$t('front-kefu-moudle-v1-tenant-reinput')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="clientID">
          <label slot="label"
            ><span style="color: red">*</span>&nbsp;clientID</label
          >
          <el-input
            v-model="openChatruleForm.clientID"
            :placeholder="$t('front-kefu-moudle-v1-clientID-reinput')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="secretID">
          <label slot="label"
            ><span style="color: red">*</span>&nbsp;secretID</label
          >
          <el-input
            v-model="openChatruleForm.secretID"
            :placeholder="$t('front-kefu-moudle-v1-secretID-reinput')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="secretID">
          <label slot="label"
            ><span style="color: red">*</span>&nbsp;{{
              $t("front-kefu-moudle-v1-publicKey")
            }}</label
          >
          <el-input
            v-model="openChatruleForm.ssosecretId"
            :placeholder="$t('front-kefu-moudle-v1-input-publicKey')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="secretID">
          <label slot="label"
            ><span style="color: red">*</span>&nbsp;{{
              $t("front-kefu-moudle-v1-single-Login-area")
            }}</label
          >
          <el-input
            v-model="openChatruleForm.ssodomain"
            :placeholder="$t('front-kefu-moudle-v1-input-single-login-area')"
          ></el-input>
        </el-form-item>
        <div class="tipBuy">
          <svg class="Iconstyle" aria-hidden="true" slot="reference">
            <use href="#icon-Warning"></use>
          </svg>
          <span>{{ $t("front-kefu-moudle-v1-zxltyhxzc-buyuser") }}</span>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeOpenChat('ruleForm')">{{
          $t("label.cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="confirmOpenChat('ruleForm')"
          :disabled="nullvalidata"
          >{{ $t("label.save") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as chatsettingsApi from "../api.js";
export default {
  props: {
    openChatDialog: {
      type: Boolean,
      default: false,
    },
    openTitle: {
      type: String,
      default: "",
    },
    openChatruleFormPass: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        activityId: "",
        clientID: "",
        secretID: "",
        ssosecretId: "",
        ssodomain: "",
      },
    },
  },

  data() {
    return {
      openChatDialogProp: this.openChatDialog,
      openChatruleFormPassProp: this.openChatruleFormPass,
      // 默认确认按钮
      nullvalidata: true,
      openChatruleForm: {
        activityId: "",
        clientID: "",
        secretID: "",
        ssosecretId: "",
        ssodomain: "",
      },
    };
  },
  computed: {
    inputData: function () {
      const { activityId, clientID, secretID, ssosecretId, ssodomain } =
        this.openChatruleForm;
      return { activityId, clientID, secretID, ssosecretId, ssodomain };
    },
  },
  watch: {
    // 监听form第二项和第三项之中有没有值
    inputData: function (newVal) {
      if (
        (newVal.activityId &&
          newVal.clientID &&
          newVal.secretID &&
          newVal.ssosecretId &&
          newVal.ssodomain) === ""
      ) {
        this.nullvalidata = true;
      } else if (
        (newVal.activityId &&
          newVal.clientID &&
          newVal.secretID &&
          newVal.ssosecretId &&
          newVal.ssodomain) !== ""
      ) {
        this.nullvalidata = false;
      }
    },
    openChatruleFormPassProp: function () {
      this.init();
    },
  },

  methods: {
    
    init() {
      this.openChatruleForm = this.openChatruleFormPassProp;
    },

    // 确认开启坐席
    confirmOpenChat(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            tenantId: this.openChatruleForm.activityId,
            clientId: this.openChatruleForm.clientID,
            secretId: this.openChatruleForm.secretID,
            ssosecretId: this.openChatruleForm.ssosecretId,
            ssodomain: this.openChatruleForm.ssodomain,
          };
          chatsettingsApi.getAccessTokenResult(params).then((res) => {
            if (res.data.result) {
              chatsettingsApi.saveHuanXinConfig(params).then((res) => {
                if (res.result) {
                  this.$emit("confirmOpenChatPass");
                  this.$refs[formName].resetFields();
                  this.$message({
                    message: this.$i18n.t("label.group.isusing.success"),
                    type: "success",
                  });
                }
              });
            } else {
              this.$message.error(
                this.$i18n.t("front-kefu-moudle-v1-zhyzcw-reinput")
              );
            }
          });
        } else {
          return false;
        }
      });
    },

    // 取消开启坐席
    closeOpenChat(formName) {
      this.$refs[formName].resetFields();
      this.$emit("closeOpenChatPass");
    },
  },
};
</script>
<style lang="scss" scoped>
.openChatDialog ::v-deep .customWidth {
  width: 472px;
}
.tipBuy {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #999999;
  .Iconstyle {
    width: 16px;
    height: 16px;
    float: left;
    margin-right: 12px;
  }
}
</style>